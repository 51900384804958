import React, { useState, useEffect } from 'react'
import './article-steps.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useMobileMode from '../../../hooks/useMobileMode';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const ArticleSteps = props => {
    const { params } = props;
    const { contenidoDelArticulo, referenciasPasoAPaso } = params;
    const { isMobile, valueWidth } = useMobileMode();

    const pasos = referenciasPasoAPaso

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [nav3, setNav3] = useState(null);

    // const [currentSlide, setCurrentSlide] = useState(1);

    var slider1;
    var slider2;
    var slider3;

    useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
        setNav3(slider3)
        return
    });

    var settings1 = {
        // lazyLoad: true,
        // dots: props.mobile ? true : false,
        // infinite: props.mobile ? true : false,
        slidesToShow: isMobile ?
            4
            :
            pasos?.length > 6 ?
                6
                :
                pasos?.length,
        focusOnSelect: true,
        arrows: false,
        // slidesToScroll: 1

    };

    var settings2 = {
        // dots: true,
        infinite: true,
        // speed: 500,
        slidesToShow: 1,
        arrows: false,
        // beforeChange: (current, next) => setCurrentSlide(next + 1),
    };

    var settings3 = {
        // dots: true,
        infinite: true,
        // speed: 500,
        slidesToShow: 1,
        fade: true,
        draggable: false,
        arrows: false,
        adaptiveHeight: true
    };

    const datalayerLinks = (labelText) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
          event: 'Interacciones_Enlaces',
          category: 'Links',
          action: 'click',
          label: labelText,
          location: location,
          user_data: [],
          log_status: '',
        })
    }

    let options = {
        renderNode: {
            // [BLOCKS.HEADING_1]: (node, next) => <h1 className={`f-${slug}`}>{next}</h1>,
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => <img className="f-aticle-content-img" src={node.data.target.fields.file['es-CO'].url} alt={node.data.target.fields.description['es-CO']} />,
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }

    return (
        <div className="f-article-steps-container">
            {documentToReactComponents(contenidoDelArticulo.json, options)}

            <Slider
                {...settings1}
                ref={slider => (slider1 = slider)}
                className={"f-prev-imgs-slider " + (isMobile ? '' : ' f-items-') + (pasos?.length > 6 ? 6 : pasos?.length)}
                asNavFor={nav3}
            >
                {pasos?.map((paso, index) => {

                    return (
                        <div className={"f-prev-img-container"} key={index}>
                            <img
                                className="f-src-img"
                                src={paso.imagenDelPaso.file.url}
                                alt={paso.imagenDelPaso.description}
                            />
                        </div>
                    )
                })}
            </Slider>
            <div className="f-slider-container">
                <Slider
                    {...settings2}
                    ref={slider => (slider2 = slider)}
                    className="f-active-imgs-slider"
                    asNavFor={nav1}
                >

                    {pasos?.map((paso, index) => {

                        return (
                            <div className="f-active-img-container" key={index}>
                                <img
                                    className="f-active-src-img"
                                    src={paso.imagenDelPaso.file.url}
                                    alt={paso.imagenDelPaso.description}
                                />
                            </div>
                        )
                    })}

                </Slider>
                <div className="f-next-prev-container">
                    <div className="f-btn-slide f-prev-btn" onClick={() => { nav1.slickPrev(); }}>
                        <i className="far fa-chevron-left"></i>
                    </div>
                    <div className="f-btn-slide f-next-btn" onClick={() => { nav1.slickNext(); }}>
                        <i className="far fa-chevron-right"></i>
                    </div>
                </div>

            </div>

            <Slider
                {...settings3}
                ref={slider => (slider3 = slider)}
                asNavFor={nav2}
                className="f-description-step-slider"
            >
                {pasos?.map((paso, index) => {

                    return (
                        <span className="f-step-description" key={index}>
                            <h2 className="f-step-title">{paso.nombreDelPaso}</h2>
                            {documentToReactComponents(paso.descripcionDelPaso.json)}
                        </span>
                    )
                })}
            </Slider>
        </div>
    )
}

export default ArticleSteps
