import React from "react";
import useMobileMode from '../../../hooks/useMobileMode';

import "./menu-anchor-articles.scss"

const MenuAnchorArticles = menuAncla => {

    const { isMobile } = useMobileMode()
    const ancla = (option) => {
        const element = document.getElementById(option);
        window.scrollTo({
            top: element?.offsetTop - (isMobile ? 90 : 200),
            behavior: "smooth",
        });
    }
    return (
    <div className="f-article-menu">
        Tabla de contenidos:
        <ul className="f-article-menu-container">
            {menuAncla.menuAncla.map((option) => <li className="f-article-menu-options" onClick={() => ancla(option.key)}>{option.titulo}</li>)}
        </ul>
    </div>
    )
}

export default MenuAnchorArticles;