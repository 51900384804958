import React from 'react'
import './article-generic.scss'
import MenuAnchorArticles from '../../atoms/menu-anchor-articles/menu-anchor-articles.jsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const ArticleGeneric = props => {
    let contTitles = 0
    const { params } = props;
    const { contenidoDelArticulo, primerosParrafos, menuAncla } = params;
    const datalayerLinks = (labelText) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
          event: 'Interacciones_Enlaces',
          category: 'Links',
          action: 'click',
          label: labelText,
          location: location,
          user_data: [],
          log_status: '',
        })
    }
    let options = {
        renderNode: {
            // [BLOCKS.HEADING_1]: (node, next) => <h1 className={`f-${slug}`}>{next}</h1>,
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                // console.log('====================================');
                // console.log(node.data.target);
                // console.log('====================================');
                
                if(node.data.target.fields){
                    const locale = process.env.LOCALE || "es-CO"
                    const defaultSrc = node.data.target.fields?.file['es-CO'].url || ''
                    const src = node.data.target.fields?.file[locale] && node.data.target.fields?.file[locale].url || defaultSrc
                    const alt = node.data.target.fields?.title[locale] || node.data.target.fields?.title['es-CO'] || ''
                    
                    return (
                        <img 
                            className="f-aticle-content-img"
                            src={src}
                            alt={alt} 
                        />
                    )
                }else{
                    return null
                }
            },
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }
    let optionsId = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, next) => {
                contTitles++;
                return <h2 id={contTitles}>{next}</h2>
            },
            [BLOCKS.HEADING_3]: (node, next) => {
                contTitles++;
                return <h3 id={contTitles}>{next}</h3>
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                if(node.data.target.fields){
                    const locale = process.env.LOCALE || "es-CO"
                    const defaultSrc = node.data.target.fields?.file['es-CO'].url || ''
                    const src = node.data.target.fields?.file[locale] && node.data.target.fields?.file[locale].url || defaultSrc
                    const alt = node.data.target.fields?.title[locale] || node.data.target.fields?.title['es-CO'] || ''
                    
                    return (
                        <img 
                            className="f-aticle-content-img"
                            src={src}
                            alt={alt} 
                        />
                    )
                }else{
                    return null
                }
            },
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }

    return (
        <div className="f-article-generic-container">
            {primerosParrafos && documentToReactComponents(primerosParrafos.json, options)}
            { menuAncla && <MenuAnchorArticles menuAncla={menuAncla}/>}
            {documentToReactComponents(contenidoDelArticulo.json, optionsId)}
        </div>
    )
}

export default ArticleGeneric
