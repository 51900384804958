import React, { useEffect } from 'react';
import './article-autopauta.scss';
import { siteUrl } from '../../../shared/utils/siteUrl';
import {
  pushDataLayerEvent,
  pushGtagEvent,
} from '../../../shared/utils/metricManagementUtil';

const ArticleAutopauta = props => {
  const { info, redirect } = props;
  const isExternal = redirect.includes("http")

  const datalayerAutoPattern = () => {
    pushDataLayerEvent({
      event: 'product_click',
      single_item_id: '',
      single_item_name: '',
      link_text: '',
      link_url: redirect,
    });
    pushGtagEvent({
      event: 'select_item',
      params: {
        ecommerce: {
          items: [
            {
            item_id: '',
            item_name: '',
            coupon: 'Ninguno',
            discount: '0',
            index: '0',
            item_list_name: '',
            item_list_id:'',
            affiliation:  '',
            item_brand: 'Familia',
            item_category: 'Productos',
            item_variant: 'Ninguno',
            price: '0',
            currency: 'COP',
            quantity: '0',
            },
          ],
        },
        item_list_name: '',
        item_list_id: '',
        units: '0',
        qualification: '0',
        user_data: [],
        log_status: ''
      },
    });
  };

  return (
    <a className="f-autopauta-container"
      href={isExternal ? redirect : siteUrl + redirect}
      target={isExternal ? '_blank' : ''}
      rel={isExternal ? 'noopener noreferrer' : ''}
      onClick={() => datalayerAutoPattern()}
    >
      <img
        className="f-autopauta-img"
        src={info.file.url}
        alt={info.description}
      />
    </a>
  );
};

export default ArticleAutopauta;
