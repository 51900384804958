import React from 'react'
import './banner-article.scss'
import useMobileMode from '../../../hooks/useMobileMode';

const BannerArticle = props => {
    const { infoBanner, title } = props;
    const { isMobile } = useMobileMode();

    return (
        <div className="f-img-container">
            <img 
                className="f-banner-img"
                src={infoBanner[isMobile ? 1 : 0].file.url}
                alt={infoBanner}
            />
            <h1 className="f-title-article">{title}</h1>
        </div>
    )
}

export default BannerArticle
