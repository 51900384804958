import React from 'react'
import './article-video.scss'
import YouTube from 'react-youtube';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const ArticleVideo = props => {
    const { params } = props;
    const { contenidoDelArticulo, enlaceDelVideo } = params;

    const datalayerLinks = (labelText) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
          event: 'Interacciones_Enlaces',
          category: 'Links',
          action: 'click',
          label: labelText,
          location: location,
          user_data: [],
          log_status: '',
        })
    }

    let options = {
        renderNode: {
            // [BLOCKS.HEADING_1]: (node, next) => <h1 className={`f-${slug}`}>{next}</h1>,
            [BLOCKS.EMBEDDED_ASSET]: (node, next) => <img className="f-aticle-content-img" src={node.data.target.fields.file['es-CO'].url} alt={node.data.target.fields.description['es-CO']} />,
            [INLINES.HYPERLINK]: (node, next) => <a href={node.data.uri} rel="noopener noreferrer" target="_blank" 
            onClick={() => datalayerLinks(next[0].props?.children ? next[0].props.children : next[0])}>{next}</a>
        }
    }

    const optionsVideo = {
        // height: '390',
        // width: '720',
        // height: heightVideo,
        width: '100%',
        // playerVars: { // https://developers.google.com/youtube/player_parameters
        //   autoplay: 1
        // },
    };

    const videoID = enlaceDelVideo !== null ? enlaceDelVideo.split('/')[enlaceDelVideo.split('/').length - 1] : null;

    return (
        <div className="f-article-video-container">
            {enlaceDelVideo !== null &&
                <YouTube
                    videoId={videoID}
                    opts={optionsVideo}
                    onPause={(e) => {
                        // let currentTime = e.target.getCurrentTime()
                        // let duration = e.target.getDuration()
                        // let total = (100 * currentTime) / duration
                    }}
                />
            }
            {documentToReactComponents(contenidoDelArticulo.json, options)}
        </div>
    )
}

export default ArticleVideo
