import React, { useState, useEffect } from 'react'
import './interaction-article.scss'
import MagazinService from '../../../services/magazin-service'
import SharedWhatsapp from '../../../shared/components/atoms/shared-whatsapp/shared-whatsapp';
import SharedFacebook from '../../../shared/components/atoms/shared-facebook/shared-facebook';
import useMobileMode from '../../../hooks/useMobileMode';
import { LocalStorage } from '../../../shared/utils/storage';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const InteractionArticle = props => {
    const { articleId, article  } = props;
    const { isMobile } = useMobileMode();

    const [extended, setextended] = useState(false)
    const [hoverLike, sethoverLike] = useState(false)
    const [isLocalLiked, setisLocalLiked] = useState(false)
    const [likesCount, setlikesCount] = useState(0)
    const [copiedTooltip, setcopiedTooltip] = useState(false)
    const [mobileHover, setmobileHover] = useState(false)
    const [linkShared, setactiveLinkShared] = useState("/")

    const iconLiked = <i
                            className="f-icon-interaction f-icon-heart fas fa-heart" 
                            onMouseEnter={() => !isMobile && sethoverLike(true)}
                            onMouseLeave={() => !isMobile && sethoverLike(false)}
                            onClick={() => {
                                handleClickLike(articleId)
                                datalayerLikesArticles()
                            }}
                        ></i>
    const iconUnliked = <i
                            className="f-icon-interaction f-icon-heart fal fa-heart" 
                            onMouseEnter={() => !isMobile && sethoverLike(true)}
                            onMouseLeave={() => !isMobile && sethoverLike(false)}
                            onClick={() => {
                                handleClickLike(articleId)
                                datalayerLikesArticles()
                            }}
                        ></i>
    
    const datalayerLikesArticles = () => {
        const subCategory = article.categoriaDelArticulo[0]?.description.description || 'Todos'
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
          event: 'Interacciones_Artículos',
          category: 'FamiTips',
          action: 'click',
          label: 'likes',
          likes: `${formatNumber(likesCount)}`,
          sub_category: subCategory,
          article_name: article.tituloDelArticulo||'',
          article_type: article.tipoDelArticulo||'',
          location: location,
          user_data: [],
          log_status: ''
        })
    }

    const datalayerShare = (action) => {
        pushDataLayerEvent({
            event: 'share',
            method: action
        })
    }

    const datalayerInteractionSharing = (text) => {
        const subCategory = article.categoriaDelArticulo[0]?.description.description || 'Todos'
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
            event: 'Interacciones_Artículos',
            category: 'FamiTips',
            action: 'click',
            label: text,
            sub_category: subCategory,
            article_name: article.tituloDelArticulo||'',
            article_type: article.tipoDelArticulo||'',
            location: location,
            user_data: [],
            log_status: ''
        })
    } 

    useEffect(() => {
        setactiveLinkShared(
          typeof window !== 'undefined'
            ? isMobile
              ? 'whatsapp://send?text=' + window.location.href
              : 'https://web.whatsapp.com/send?text=' + window.location.href
            : '/'
        );
        
        if(LocalStorage.getItem('ARTICLES_LIKES')){
            let localList = LocalStorage.getItem('ARTICLES_LIKES');   
            if (localList.includes(articleId)) {
                setisLocalLiked(true);
            }
        }
        

        MagazinService.getArticleLike(articleId).then(res => {
            if (200 === res.status) {
                setlikesCount(res.data[0].like)
            } else {
                console.log(res)
            }
        }).catch(e => console.log(e))
    }, [])

    const handleClickLike = (id) => {

        if (isMobile) {
            setmobileHover(true)
            setTimeout(() => {
                setmobileHover(false)
            }, 2000);
        }

        if (isLocalLiked) { //set unlike

            let payload = {
                like: false,
                cmsId: id
            }

            MagazinService.setLikeStatus(payload).then(res => {
                if (200 === res.status) {
                    setlikesCount(res.data)
                    let localList = LocalStorage.getItem('ARTICLES_LIKES')
                    LocalStorage.setItem('ARTICLES_LIKES', localList.filter(e => e !== id))
                    setisLocalLiked(false)
                    if (!isMobile) {
                        sethoverLike(false)
                    }
                } else {
                    console.log(res)
                }
            }).catch(e => console.log(e))
        } else { //set like

            let payload = {
                like: true,
                cmsId: id
            }

            MagazinService.setLikeStatus(payload).then(res => {
                if (200 === res.status) {
                    setlikesCount(res.data)
                    if (LocalStorage.getItem('ARTICLES_LIKES')) {
                        let localList = LocalStorage.getItem('ARTICLES_LIKES')
                        LocalStorage.setItem('ARTICLES_LIKES', localList.concat([id]))
                        setisLocalLiked(true)
                    } else {
                        LocalStorage.setItem('ARTICLES_LIKES', [id])
                        setisLocalLiked(true)
                    }

                } else {
                    console.log(res)
                }
            }).catch(e => console.log(e))
        }
    }

    const formatNumber = (num) => {
        if (num > 999999) {
            return parseInt(num / 1000000, 10).toString() + 'M'
        } else if (num > 999) {
            return parseInt(num / 1000, 10).toString() + 'K'
        } else {
            return num
        }
    }

    const copyToClipboard = () => {
        var dummy = document.createElement('input'), text = window.location.href;
        dummy.classList.add("f-dummy-absolute")
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);

        setcopiedTooltip(true)
        setTimeout(() => {
            setcopiedTooltip(false)
        }, 5000);
    }

    return (
        <div className={"f-interaction-article-position " + (extended ? 'f-extended-w' : '')}>
            <div className="f-interaction-container">
                {isLocalLiked || hoverLike ? iconLiked : iconUnliked}
                <div className={"f-tooltip-interaction f-count-box " + (hoverLike || mobileHover ? 'f-show-tooltip' : '')}>
                    {formatNumber(likesCount)}
                </div>
                <span className="f-separator-line" />
                <div className="f-collapse-menu">
                    <div className={"f-hidden-menu " + (extended ? 'f-showing-options' : '')}>
                        {/* <i className="f-icon-interaction fab fa-facebook-f"></i>
                        <i className="f-icon-interaction fab fa-whatsapp"></i> */}
                        <SharedFacebook onClick={()=> {datalayerShare('Facebook'); datalayerInteractionSharing('Facebook')}} href={typeof window !== 'undefined' ? window.location.href : "/"}>
                            <span className="f-icon-interaction fab fa-facebook-f"></span>
                        </SharedFacebook>
                        <SharedWhatsapp onClick={() => {datalayerShare('Whatsapp'); datalayerInteractionSharing('Whatsapp')}} href={linkShared} />
                        <i className="f-icon-interaction fal fa-copy" onClick={() => {copyToClipboard(); datalayerShare('Copiar enlace'); datalayerInteractionSharing('Copiar enlace')}}></i>
                        <i className="f-icon-interaction f-icon-close fal fa-times" onClick={() => setextended(false)}></i>
                    </div>
                    <div className={"f-icon-activate " + (extended ? 'f-hidden-share' : '')}>
                        <i className="f-icon-interaction fal fa-share-alt" onClick={() => {
                            setextended(true)
                        }}></i>
                    </div>
                </div>
                <div className={"f-tooltip-interaction f-copied-tooltip " + (copiedTooltip ? 'f-show-tooltip' : '')}>
                    ¡Copiado!
                </div>
            </div>
        </div>
    )
}

export default InteractionArticle
