import React, { useState } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import SEO from '../../../shared/components/seo';
import { siteUrl } from '../../../shared/utils/siteUrl';
import { stringUnicode } from '../../../shared/utils/utilities-seo';
import ArticleContent from '../../organisms/article-content/article-content';
import Layout from '../../organisms/layout/layout';

const ArticleTemplate = props => {

    const { pageContext } = props
    const { params, next, prev } = pageContext
    const { isMobile } = useMobileMode();

    const menuActive = 4;
    const [modalActive, setmodalActive] = useState(null);
    const path = props.pageContext.path;

    let datePublished = "";
    let dateModified = "";
    const imageSeo = `https:${params.metaImagen?.file?.url}`;
    try {
        datePublished = new Date(params.fechaDePublicacionDelArticulo);
        dateModified = new Date(params.fechaDePublicacionDelArticulo);
    } catch (e) {
        console.error("Error al cambiar la fecha", e);
    }

    let schemaBlog = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": path
        },
        "headline": stringUnicode(params.metaTitulo),
        "description": stringUnicode(params.metaDescripcion.metaDescripcion),
        "image": imageSeo,
        "author": {
            "@type": "Organization",
            "name": "Familia"
        },
        "publisher": {
            "@type": "Organization",
            "name": "Grupo Familia S.A",
            "logo": {
                "@type": "ImageObject",
                "url": "/logo.png",
                "width": "",
                "height": ""
            }
        },
        "datePublished": datePublished,
        "dateModified": dateModified
    };

    const schemaBlogJSONLD = {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(schemaBlog),
    };

    return (
        <Layout
            menuActive={menuActive}
            footerActive={true}
            headerActive={!isMobile}
            setmodalActive={setmodalActive}
            modalActive={modalActive}
            classes={{ main: 'white-blue-back', footer: 'white-blue-back' }}
            article={params}
            title={params.metaTitulo}
            slug={`/famitips/${params.slug}`}
        >
            <SEO
                slug={`/famitips/${params.slug}`}
                title={params.metaTitulo}
                description={params.metaDescripcion?.metaDescripcion}
                image={imageSeo} 
                script={schemaBlogJSONLD}
                alternatesInvalidCountries={params.paisesDeRestriccionDelArticulo}
            />
            <ArticleContent
                params={params}
                next={next}
                prev={prev}
            />
        </Layout>
    )
}

export default ArticleTemplate
