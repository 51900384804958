import React from 'react'

import './shared-whatsapp.scss'

const SharedWhatsapp = ({ onClick, href }) => {

    return (
        <a className="f-shared-whatsapp" onClick={() => { if(onClick) onClick() }} href={href} data-action="share/whatsapp/share" target="_black">
            <svg className="f-icon-whatsapp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <g id="whats" transform="translate(-754 -641)">
                    <rect id="Rectángulo_750" data-name="Rectángulo 750" width="52" height="52" transform="translate(754 641)" opacity="0.004" />
                    <path id="Trazado_12558" data-name="Trazado 12558" d="M586.807,377.782l-8.1-5.078-.018-.018a2.167,2.167,0,0,0-.515-.231h-.036c-.029-.007-.059-.013-.089-.018h-.018a1.47,1.47,0,0,0-.835.284.632.632,0,0,0-.036.053,1.213,1.213,0,0,0-.124.124l-1.771,1.8c-3.219-1.62-5.7-4.337-7.919-8.008l1.634-1.616a1.237,1.237,0,0,0,.089-.089c.025-.023.048-.046.071-.071v-.018c.025-.023.048-.046.071-.071V364.8a.411.411,0,0,1,.036-.053l.036-.035c.013-.029.025-.059.036-.089v-.018l.018-.035c.013-.023.025-.047.036-.071a.23.23,0,0,0,.018-.089v-.018a.949.949,0,0,0,.018-.107v-.249c-.005-.03-.011-.059-.018-.089v-.018a1.107,1.107,0,0,0-.071-.2v-.018a1.114,1.114,0,0,0-.071-.142l-.018-.035-.018-.018-5.06-8.31a1.138,1.138,0,0,0-.941-.55H563.1l0,.005a1.115,1.115,0,0,0-.249.053,8.6,8.6,0,0,0-5.291,3.942,8.914,8.914,0,0,0-.675,6.019c.831,4.263,3.78,8.844,7.511,12.66s8.228,6.87,12.518,7.759a9.083,9.083,0,0,0,6.126-.639,9.22,9.22,0,0,0,4.226-5.344A1.137,1.137,0,0,0,586.807,377.782Zm-4.814,4.643a6.814,6.814,0,0,1-4.616.426c-3.56-.738-7.86-3.536-11.364-7.12s-6.216-7.942-6.907-11.488a6.671,6.671,0,0,1,.462-4.51,5.752,5.752,0,0,1,3.126-2.566v0L566.832,364l-1.722,1.7a1.136,1.136,0,0,0-.178,1.367c2.628,4.551,5.742,8.092,10.1,10.014a1.137,1.137,0,0,0,1.261-.231l1.829-1.829,6.676,4.173A6.309,6.309,0,0,1,581.993,382.425Zm3.08-33.728A25,25,0,0,0,572,345l0,0a24.995,24.995,0,0,0-20.9,38.719l-2.8,9.33a1.136,1.136,0,0,0,1.459,1.4L558.9,391.3a25,25,0,1,0,26.177-42.6Zm-13.086,44.029a22.72,22.72,0,0,1-12.323-3.635,1.138,1.138,0,0,0-.989-.121l-7.552,2.606,2.312-7.717a1.133,1.133,0,0,0-.149-.966A22.726,22.726,0,0,1,572,347.273h0a22.727,22.727,0,0,1-.013,45.454Z" transform="translate(208 297.003)" />
                </g>
            </svg>
        </a>
    )
};

export default SharedWhatsapp
