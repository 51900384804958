import React, { useEffect } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import { getColorByCategory } from '../../../shared/utils/getColorCategory';
import { getMonth } from '../../../shared/utils/getMonth';
import { siteUrl } from '../../../shared/utils/siteUrl';
import ArticleAutopauta from '../../atoms/article-autopauta/article-autopauta';
import BannerArticle from '../../atoms/banner-article/banner-article';
import InteractionArticle from '../../atoms/interaction-article/interaction-article';
import ArticleGeneric from '../../molecules/article-generic/article-generic';
import ArticleSteps from '../../molecules/article-steps/article-steps';
import ArticleVideo from '../../molecules/article-video/article-video';
import SectionCardArticles from '../../molecules/section-card-articles/section-card-articles';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import './article-content.scss';
import { pushDataLayerEvent, pushGtagEvent } from '../../../shared/utils/metricManagementUtil';

const ArticleContent = props => {

    const { params, next, prev } = props
    const { articulosRelacionados, contentful_id, autopautaDelArticulo, redireccionAutopauta } = params
    const { isMobile } = useMobileMode();

    const date = {
        day: params.fechaDePublicacionDelArticulo.split('/')[2],
        month: getMonth(params.fechaDePublicacionDelArticulo.split('/')[1] - 1),
        year: params.fechaDePublicacionDelArticulo.split('/')[0]
    }

    const getComponentByType = type => {
        if (type === 'Paso a Paso') {
            return <ArticleSteps params={params} />
        } else if (type === 'Artículo' || type === 'Tips') {
            return <ArticleGeneric params={params} />
        } else if (type === 'Video' || type === 'Poopcast') {
            return <ArticleVideo params={params} />
        } else {
            return <ArticleGeneric params={params} />
        }
    }


    const datalayerPrevNext = (labelText) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
            event: 'Interacciones_Artículos',
            category: 'anterior/siguiente',
            action: 'click',
            label: labelText,
            location: location,
            user_data: [],
            log_status: ''
        })
    }

    const datalayerArticleView = (labelText, subCategory, articleType) => {      
        let location=''
        if (typeof window !== 'undefined') {
            location= window.location.href
        }
        pushDataLayerEvent({
            event: 'Article_View',
            category: 'FamiTips',
            action: 'view',
            label: labelText,
            sub_category: subCategory,
            article_name: labelText,
            article_type: articleType,
            location: location,
            user_data: [],
            log_status: ''
        })
    }

    const datalayerEvent = () => {
    
        pushGtagEvent({
            event: 'view_item_list',
            params: {
                ecommerce: {
                items: {
                    item_id: 'Ninguno',
                    item_name: 'Ninguno',
                    coupon: 'Ninguno',
                    discount: '0',
                    index: '0',
                    item_list_name: 'Ninguno',
                    item_list_id: 'Ninguno',
                    affiliation: 'Ninguno',
                    item_brand: 'Ninguno',
                    item_category: 'Ninguno',
                    item_variant: 'Ninguno',
                    price: '0',
                    currency: 'COP',
                    quantity: '0',
                },
                },
                item_list_name: 'Ninguno',
                item_list_id: 'Ninguno',
                user_data: [],
                log_status: '',
            },
        });
        
    };

    useEffect( () => {
        datalayerArticleView(params.tituloDelArticulo, params.categoriaDelArticulo ? params.categoriaDelArticulo[0].description.description : '', params.tipoDelArticulo)
        datalayerEvent();
    }, [])

    return (
        <div className="f-article-content">
            <div className="f-bread-crumbs">
                <a href={siteUrl + '/famitips/'} className={"nostyle"}>
                    <label>{'FAMITIPS / '}</label>
                </a>
                {params.tituloDelArticulo.toUpperCase()}
            </div>

            <div className="f-helmet-backbutton">
                <a href={siteUrl + '/famitips/'} className={'f-icon-backbutton'}><i className="far fa-arrow-left"></i></a>
                <span>FAMITIPS</span>
            </div>


            <div className="f-article-box">
                <div className="f-article-info-container">
                    <BannerArticle infoBanner={params.bannerDelArticulo} title={params.tituloDelArticulo} />
                    <InteractionArticle articleId={contentful_id} article={params} />
                    {!isMobile && <ArticleAutopauta info={autopautaDelArticulo} redirect={redireccionAutopauta} article={params} />}
                    <div className="f-category-container">
                        <div className={"f-category-article f-background-category-article-" + getColorByCategory(params.categoriaDelArticulo[0].description.description)}>
                            {params.categoriaDelArticulo[0].description.description.toUpperCase()}
                        </div>
                        <span className="f-type-article-tag">{params.tipoDelArticulo.toUpperCase()}</span>
                    </div>

                    <div className="f-date-container-article">
                        {date.day + ' de ' + date.month.toLowerCase() + ' de ' + date.year}
                    </div>

                    <div className="f-article-margin-container">
                        {getComponentByType(params.tipoDelArticulo)}

                        <div className="f-next-prev-article">
                            {prev &&
                                <a className="f-pagination-container f-prev-article" href={siteUrl + removeDoubleSlash('/famitips/' + prev.slug)} onClick={() => {
                                    datalayerPrevNext(prev.slug)
                                }}>
                                    <div className="f-btn-slide f-prev-btn">
                                        <i className="far fa-chevron-left"></i>
                                    </div>
                                    <span className="f-label-prev">
                                        Anterior artículo
                                    </span>
                                </a>
                            }
                            {prev && next && <span className="f-page-separator" />}
                            {next &&
                                <a className="f-pagination-container f-next-article" href={siteUrl +removeDoubleSlash('/famitips/' + next.slug)} onClick={() => {
                                    datalayerPrevNext(next.slug)
                                }}>
                                    <span className="f-label-next">
                                        Siguiente artículo
                                    </span>
                                    <div className="f-btn-slide f-next-btn">
                                        <i className="far fa-chevron-right"></i>
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {isMobile && <div className="f-autopauta-container-position">
                <ArticleAutopauta info={autopautaDelArticulo} redirect={redireccionAutopauta} article={params} />
            </div>}

            {articulosRelacionados &&
                <div className="f-article-bottom-info" onClick={() => datalayerArticlesRecommended(params.tituloDelArticulo)}>
                    <div className="f-title-container">
                        <span className="f-title-articles-recommended">También puedes ver</span>
                    </div>
                    <SectionCardArticles articlesList={articulosRelacionados} isSuggest={true} />
                    <span className="f-separator-h" />
                </div>
            }
        </div>
    )
}

export default ArticleContent
