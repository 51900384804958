import React from 'react'
import { FacebookProvider, Share } from 'react-facebook';

import './shared-facebook.scss'

const SharedFacebook = ({ onClick, href, children }) => {

    return (
        <FacebookProvider appId="629704740919073">
            <Share href={href}>
                {({ handleClick, loading }) => (
                    <div 
                        onClick={(e) => {
                            handleClick(e)
                            if(onClick) onClick()
                    }}>
                        {children}
                    </div>
                )}
            </Share>
        </FacebookProvider>
    )
};

export default SharedFacebook
